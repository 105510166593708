import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';
import Headline from '../components/ui/headline';
import Text from '../components/ui/text';
import { useLocale } from '../context/locale.context';
import { getCopy } from '../helpers/copy';
import Container from '../modules/container';
import Layout from '../modules/layout';
import * as styles from '../styles/newsletter.module.scss';
import { StringParam, useQueryParam } from 'use-query-params';
import { getContactFormFetchOptions } from '../helpers/fetch';

const LandingPageFormConfirmation = () => {
  const [ref] = useQueryParam('ref', StringParam);
  const [submitted, setSubmitted] = useState(false);
  const { locale } = useLocale();
  const { allContentfulMicrocopy } = useStaticQuery(
    graphql`
      query {
        allContentfulMicrocopy {
          nodes {
            node_locale
            key
            value
          }
        }
      }
    `
  );
  const microcopy = allContentfulMicrocopy.nodes
    .filter((node) => node.node_locale === locale)
    .reduce((acc, curr) => {
      return {
        ...acc,
        [curr.key]: curr.value,
      };
    }, {});

  useEffect(() => {
    const url =
      process.env.GATSBY_LANDING_PAGE_CONTACT_FORM_OPT_IN_CONFIRMATION;

    const opts = getContactFormFetchOptions({
      doiRefId: ref,
    });
    fetch(url, opts).then((res) => setSubmitted(res.ok));
  }, []);

  return (
    <Layout
      title={getCopy('title.landingPageFormConfirmation', microcopy)}
      description={getCopy('desc.landingPageFormConfirmation', microcopy)}
      slug={'confirm'}
    >
      <Container className={styles.newsletterWrapper}>
        {submitted ? (
          <>
            <Headline
              level="h1"
              type="h1"
              text={getCopy('landingPageFormConfirmation.headline', microcopy)}
            />
            <Text as="p" variant="light" type="p1">
              {getCopy(
                'landingPageFormConfirmation.confirmationText',
                microcopy
              )}
            </Text>
          </>
        ) : (
          <Text as="p" variant="light" type="p1">
            {getCopy('landingPageFormConfirmation.processingText', microcopy)}
          </Text>
        )}
      </Container>
    </Layout>
  );
};

export default LandingPageFormConfirmation;
